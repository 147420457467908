import React from "react";
import { Container } from 'react-bootstrap';
import Helmet from 'react-helmet';

/**
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Components
 */
import MainContent from '@components/main-content/main-content';
import TopBanner from '@components/top-banner/top-banner';
import PostContent from "@components/post-content/post-content";
import Map from '@components/map/map';
import EntryHeading from "@components/entry-heading/entry-heading";
import Cards from "@components/cards/cards";
import Offices from "@components/offices/offices";
import ReviewsGrid from "@components/reviews-grid/reviews-grid";
import Contact from "@components/contact/contact";

const Static = () => {
  return (
    <Layout>
      <Helmet 
        bodyAttributes={{
          class: 'static'
        }}
      />
      <Map />
    </Layout>
  );
}

export default Static;